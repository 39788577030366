//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import headerView from '@/views/components/ishop/header'
  export default {
    name: 'search',
    data() {
      return {
        headValue: {
          haveSearch: true,
          haveBack: true,
        }
      }
    },
    components: {
      headerView
    }
  }
